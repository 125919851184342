.skills-container-main {
  --accent: #8855ff;
  height: 100vh;
  background-color: #FEFEFF;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 150px;
}





.window {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: white;
    overflow: hidden;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
      0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
      0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
    display: flex;
    flex-direction: column;
    border: 1px solid #E7ECF9 ;
  }
  
  .tab-nav {
    background: #fdfdfd;
    padding: 5px 5px 0;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #eeeeee;
    height: 44px;
  }
  
  .tabs {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;
    width: 100%;
  }
  
  .main-container {
    /* display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120px;
    flex-grow: 1;
    user-select: none; */
    color: black;
  }

  .skilltitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .ul-list,
.list-item {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
  }
  
 .ul-list {
    display: flex;
    width: 100%;
  }
  
 .list-item {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    padding: 0px 15px;
    position: relative;
    background: white;
    cursor: pointer;
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 0;
    position: relative;
    user-select: none;
    color:black;
  }
  
  .underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    top:30;
    height: 1px;
    background: var(--accent);
  }
  
  .list-item.selected {
    background: #eee;
  }
  
  .list-item button {
    width: 20px;
    height: 20px;
    border: 0;
    background: #fff;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    stroke: #000;
    margin-left: 10px;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #fff;
  }
  
  
  