html, body {
  height: 100%;
  margin: 0;
  font-family: "eurostile", sans-serif !important;
  font-size: 16px;
  color: white !important;
}

body::-webkit-scrollbar { 
  width: 0 !important;
  display: none; 
}



.head-container{
  display: block;
  /* display: flex; */
  /* height: 100vh; */
  width: 100vw;
  background: #0C0C0C;
  /* flex-wrap: wrap; */
}

.head-container  > div {
  flex: 1;
}



.content-head{
  height: 90vh;
  width: 100vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .head-text{
      font-size: 30px;
      padding-top: 100px;
      overflow-x: visible;
  }
}


.jagged-top {
  stroke: white;
  stroke-width: 4;
  transition: 1s;
}

.jagged-top1 {
  stroke: black;
  stroke-width: 4;
  transition: 1s;
}



.menu-container{
  position: relative;
  top: 10px;
  right: 10px;
  z-index: 99;
}


.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.contact-btn {
  position: relative !important;
  color: white;
  margin-top: 30px;
  width: 30% !important;

}

.skills-container {
  display: block;
  position: relative;
  margin-top: 50px;
  height: 100%;
  width: 100%;
  color:black;
}

.subheading {
  color: #F2AA4C;
  padding-left: 5px;
}

.child {
  position: absolute;
  top: 48%;  /* position the top  edge of the element at the middle of the parent */
  left: 40%; /* position the left edge of the element at the middle of the parent */
  -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.child1 {
  position: absolute;
  top: 28%;  /* position the top  edge of the element at the middle of the parent */
  left: 30%; /* position the left edge of the element at the middle of the parent */
  -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

table td {
  border-right: 1px solid #000;
}

.service-container {
  display: block;
  color:white;
  background: #0C0C0C;
  padding-bottom: 100px;
  position: relative;
  height: 100%;
  width: 100%;
}




.floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 15px); }
    to   { transform: translate(0, -0px); }    
}